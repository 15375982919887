import React from "react";



export default function HomeButton(props) {


  return (
    <div className="returnHome">
      <button className="magenta-bg"><a href="/"><h2>Return to main page</h2></a></button>
    </div>
  )

}